import * as _ from 'lodash-es';
import { useMemo } from 'react';
import {
  parse as parsePath,
  match as matchPath,
  tokensToFunction,
  MatchResult,
} from 'path-to-regexp';
import { createPath } from 'history';
import { useRouteMatch } from 'react-router-dom';

import { encodeQuery, InputQueryObj } from 'lib/utils';
import { getCurrentUrl, getHost, IS_DEV } from 'lib/env';
import type { InferPathParams } from 'types/inferPathParams';

type UnionMaybeArray<T> = T extends readonly (infer U)[] ? U : T;

type EmptyIsOptional<T> = keyof T extends never
  ? void | Record<string, never>
  : T;

type BuildRouteReturn<
  Params extends Record<string, any>,
  Pattern extends readonly string[],
> = {
  pattern: Pattern;
  path: (params: EmptyIsOptional<Params>, query?: InputQueryObj) => string;
  url: (params: EmptyIsOptional<Params>, query?: InputQueryObj) => string;
  match: (path: string) => MatchResult<Params> | null;
};

type AnyBuildRouteReturn = BuildRouteReturn<any, any>;

export const buildRoute = <
  InputPattern extends string | readonly string[],
  OutputPattern extends UnionMaybeArray<InputPattern>[],
  Params extends InferPathParams<UnionMaybeArray<InputPattern>>,
>(
  /** Must order by reverse specificity */
  pattern: InputPattern,
): BuildRouteReturn<Params, OutputPattern> => {
  // TODO: sort by specificity here so we can stop requiring the order of `pattern`
  // (NOTE: react-router v6 will do this for us: https://github.com/remix-run/react-router/blob/5f3cfb7ac2ad823ecdf69069332567e35ad795c6/packages/router/utils.ts#L328)
  const patterns = (
    Array.isArray(pattern) ? [...pattern].reverse() : [pattern]
  ) as OutputPattern;

  const pathFns = patterns.map((p) => {
    const tokens = parsePath(p);
    const pathFn = tokensToFunction(tokens);

    const keys = tokens.map((t) => (typeof t === 'string' ? t : t.name));

    return (params: Record<string, any>): string | null => {
      try {
        const path = pathFn(params);
        return path + encodeQuery(_.omit(params, keys), true);
      } catch {
        return null;
      }
    };
  });

  const getPath = (params: EmptyIsOptional<Params>, query?: InputQueryObj) => {
    for (const pathFn of pathFns) {
      const path = pathFn(params || {});
      if (path != null) return path + (query ? encodeQuery(query, true) : '');
    }

    throw new Error(
      `Failed to build route "${patterns.join(
        ', ',
      )}" with params ${JSON.stringify(params)}`,
    );
  };

  const matcher = matchPath(patterns);

  return {
    pattern: patterns,
    path: getPath,
    url: (params, query) => `${getHost() || ''}${getPath(params, query)}`,
    match: (path) => (matcher(path) as any) || null,
  };
};

export const routePrefixFactory =
  <Prefix extends string>(prefix: Prefix) =>
  <SubPath extends readonly string[] | string>(subPath: SubPath) =>
    buildRoute(
      (typeof subPath === 'string'
        ? `${prefix}${subPath}`
        : subPath.map(
            (p) => `${prefix}${p}`,
          )) as `${Prefix}${UnionMaybeArray<SubPath>}`,
    );

type StringValues<T> = {
  [K in keyof T]: string;
};

export type InferBuildRouteParams<RouteReturn extends AnyBuildRouteReturn> =
  ExclusifyUnion<StringValues<NonNullable<Parameters<RouteReturn['path']>[0]>>>;

export const useRouteParams = <RouteReturn extends AnyBuildRouteReturn>(
  /** Must order by reverse specificity */
  routeOrRoutes: RouteReturn | readonly RouteReturn[],
  options: { strict?: boolean; exact?: boolean } = {},
) => {
  const patterns = useMemo(
    () =>
      _.flatten([routeOrRoutes])
        .reverse()
        .flatMap((r) => r.pattern),
    [routeOrRoutes],
  );
  const match = useRouteMatch<InferBuildRouteParams<RouteReturn>>({
    path: patterns,
    ...options,
  });
  return match?.params || null;
};

const PRODUCTION_ORIGIN = 'https://classroom.stepful.com';

export type NavType = 'external' | 'frontend' | 'backend';

export type LocationDescriptor = {
  pathname: string;
  search?: string;
  hash?: string;
  origin?: string;
  state?: unknown;
};

/**
 * Each "route group" represents a different SPA.
 * Navigation between different route groups must be done via a full page reload,
 * while navigation within the same route group can be done via the frontend router.
 */
export const urlNavigationTypeFactory = (
  ...routeGroups: AnyBuildRouteReturn[][]
) => {
  const groupsMatchers = routeGroups.map((routes) =>
    matchPath(_.uniq(routes.flatMap((r) => r.pattern))),
  );

  /**
   * A function that takes a URL and returns the type of navigation.
   *
   * Navigation types:
   * - "external": the URL does not match the current origin
   * - "frontend": if the URL can be navigated to by the frontend router.
   *   This is only possible if the current URL is within the same <Router/> i.e. same `routeGroup`
   * - "backend": the URL must be navigated to by normal browser navigation e.g. `<a href={url}/>` or `window.location.href = url`
   */
  return (
    url: LocationDescriptor | string,
    currentUrl: LocationDescriptor = getCurrentUrl(),
  ): [navType: NavType, cleanHref: string] => {
    if (url !== null && typeof url === 'object') {
      url = (url.origin || '') + createPath(url);
    }

    try {
      // mailto:, tel:, etc. urls are always "external"
      if (/^(mailto|tel|data):/.test(url)) return ['external', url];

      // e.g. '' or '#some-anchor' or '?some-query'
      if (url === '' || url.startsWith('#') || url.startsWith('?')) {
        const currentlyUsingReactRouter = groupsMatchers.some((m) =>
          m(currentUrl.pathname),
        );
        return [
          // if we're using react-router, it can handle these special urls
          currentlyUsingReactRouter ? 'frontend' : 'backend',
          url,
        ];
      }

      const origin = currentUrl.origin || getHost();
      let parsed = new URL(url, origin);

      // if we encounter an absolute url from the production DB during development, treat it as a non-external url
      if (IS_DEV && parsed.origin === PRODUCTION_ORIGIN) {
        url = url.replace(PRODUCTION_ORIGIN, origin);
        parsed = new URL(url, origin);
      }

      if (parsed.origin !== origin) return ['external', url];

      const givenGroup = groupsMatchers.find((m) => m(parsed.pathname));
      const currentGroup = groupsMatchers.find((m) => m(currentUrl.pathname));

      return givenGroup && givenGroup === currentGroup
        ? ['frontend', parsed.pathname + parsed.search + parsed.hash] // react-router must have a relative url
        : ['backend', url];
    } catch {
      // unexpected error!
      return ['external', url];
    }
  };
};
