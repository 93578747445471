import { Box, Link as CLink, Stack, Text } from '@chakra-ui/react';

import type { ErrorFallbackProps } from 'components/ErrorBoundary';

import sadSnakeImgUrl from '../../assets/images/sad-snake.svg';

/**
 * User-facing error page. Does not show `error.message`.
 */
export const ErrorPage: React.FC<
  ErrorFallbackProps & { message?: React.ReactNode }
> = ({ message = 'An unexpected error occurred' }) => (
  <Stack
    spacing="12"
    direction={{ base: 'column', md: 'row' }}
    align="center"
    justify="center"
    pt="32"
  >
    <Box flex="1" textAlign="right">
      <Text mb="4" fontSize="8xl" fontWeight="bold">
        Oops!
      </Text>
      <Text color="red.500" fontSize="xl" mb="2">
        {message}
      </Text>
      <Text color="gray.500" mr="10">
        Click <CLink href="/">here</CLink> to try again.
      </Text>
    </Box>
    <Box flex="1">
      <img src={sadSnakeImgUrl} alt="Sad snake" style={{ maxWidth: 350 }} />
    </Box>
  </Stack>
);
