import { StrictMode } from 'react';
import { renderToString } from 'react-dom/server'; // TODO: exclude this from client bundle
import { ChakraProvider } from '@chakra-ui/react';
import {
  Router as BrowserRouter,
  StaticRouter,
  StaticRouterProps,
} from 'react-router-dom';
import type { ReactComponentOrRenderFunction } from 'react-on-rails/node_package/lib/types';
import { SWRConfig } from 'swr';

import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { chakraTheme } from 'lib/chakraTheme';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { HeadCtx, HeadProvider } from 'components/Head';
import { ErrorPage } from 'components/ErrorPage';
import {
  RailsCtx,
  SessionProvider,
  useSessionContext,
} from 'components/SessionContext';
import { setSSRMountUrl } from 'lib/env';
import { setTrackingContext } from 'lib/errorTracking';
import { getBrowserHistory } from 'components/Link';
import { request } from 'lib/request';
import { useUserData } from 'lib/userData';
import { setupAutoTracking } from 'lib/trackingHelpers/eventTracking';
import { getSWRegistration } from 'lib/service-worker/client';

if (typeof window !== 'undefined') {
  setupAutoTracking();
  void getSWRegistration();
}

const SetTrackingContext: React.FC = () => {
  const railsCtx = useSessionContext();
  const user = useUserData(true);
  try {
    setTrackingContext({
      user,
      impersonating: railsCtx ? !!railsCtx.impersonating : undefined,
    });
  } catch {}
  return null;
};

type RouterCtx = NonNullable<StaticRouterProps['context']> & {
  location?: { pathname: string; search: string };
};

export const defaultFetcher = <Res,>(queryKey: unknown) => {
  const [url, method] = Array.isArray(queryKey) ? queryKey : [queryKey];

  if (
    typeof url !== 'string' ||
    (method !== undefined && typeof method !== 'string')
  ) {
    throw new Error(
      `Invalid query key in defaultFetcher: ${JSON.stringify(queryKey)}`,
    );
  }

  return request<Res>({
    url,
    method,
  });
};

// global react-query client
export const queryDefaultOptions: DefaultOptions<unknown> = {
  queries: {
    queryFn: (ctx) => defaultFetcher(ctx.queryKey),
  },
};
const queryClient = new QueryClient({
  defaultOptions: queryDefaultOptions,
});

const AppContainer: React.FC<{
  preloadData: Record<string, unknown> | undefined;
  children: React.ReactNode;
  isPage: boolean;
  railsCtx: RailsCtx;
  headCtx?: HeadCtx;
  hasError?: boolean;
}> = ({ preloadData, children, isPage, railsCtx, headCtx, hasError }) => {
  // We can't put ErrorBoundary around Router since it relies on react-router useLocation
  return (
    <QueryClientProvider client={queryClient}>
      <SWRConfig
        value={{ fallback: preloadData || {}, fetcher: defaultFetcher }}
      >
        {/* Set tracking context as early as possible so errors/events are properly associated to the user.
        SetTrackingContext relies on SWR fallbackData and defaultFetcher, so it must be within the SWRConfig. */}
        <SetTrackingContext />
        <SessionProvider value={railsCtx}>
          <HeadProvider context={headCtx}>
            <ChakraProvider theme={chakraTheme} resetCSS>
              <ErrorBoundary
                hasError={hasError}
                fallback={isPage ? ErrorPage : undefined}
              >
                {children}
              </ErrorBoundary>
            </ChakraProvider>
          </HeadProvider>
        </SessionProvider>
      </SWRConfig>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

type ReactOnRailsEntry<Props extends Record<string, any>> = {
  (props: Props, railsCtx: RailsCtx):
    | React.FC
    | {
        routeError?: Error;
        error?: Error;
        renderedHtml?:
          | string
          | {
              componentHtml: string;
              headHtml?: string;
              headTitle?: string;
              redirectTo?: string;
            };
        // `redirectLocation` from `react-on-rails` doesn't do anything, so we use our custom prop `redirectTo` instead
        // redirectLocation?: { pathname: string; search: string };
      };
  renderFunction?: boolean;
};

// See `app/helpers/preload_data_helper.rb` for the server-side implementation
const PROPS_PRELOAD_KEY = '__preload';
const PROPS_IS_PAGE_KEY = '__is_page';

export const withAppContainer = <Props extends Record<string, any>>(
  Comp: React.FC<Props>,
) => {
  const renderFn: ReactOnRailsEntry<Props> = (rootProps, railsCtx) => {
    const {
      [PROPS_IS_PAGE_KEY]: isPage = false,
      [PROPS_PRELOAD_KEY]: preloadData,
      ...restProps
    } = rootProps as Props & {
      [PROPS_IS_PAGE_KEY]?: boolean;
      [PROPS_PRELOAD_KEY]?: Record<string, unknown>;
    };
    const props = restProps as Props;

    if (typeof window === 'undefined') {
      const routerCtx: RouterCtx = {};
      const headCtx: HeadCtx = {};

      // HACK: make the page's url globally available.
      // TODO: it's better to use React context instead of global variables because
      // react-on-rails might reuse the JavaScript process for later web requests
      setSSRMountUrl(railsCtx.href);

      let html: string | undefined;
      let renderError: {
        errorMessage: string;
        errorStack?: string;
        fallbackErrorMessage?: string;
        fallbackErrorStack?: string;
      } | null = null;
      for (const hasError of [false, true]) {
        // when `hasError` is true, we render our fallback error page
        try {
          html = renderToString(
            <StaticRouter location={railsCtx.location} context={routerCtx}>
              <AppContainer
                preloadData={preloadData}
                hasError={hasError}
                isPage={isPage}
                railsCtx={railsCtx}
                headCtx={headCtx}
              >
                <Comp {...props} />
              </AppContainer>
            </StaticRouter>,
          );
          break;
        } catch (err) {
          console.error('RENDER ERROR:');
          console.error((err as Error).message);

          if (!hasError)
            renderError = {
              errorMessage: (err as Error).message || 'Unknown render error',
              errorStack: (err as Error).stack,
            };
          // handle error in fallback error page.
          // this should never happen
          else
            renderError = {
              ...(renderError || { errorMessage: 'unknown render error' }),
              fallbackErrorMessage:
                (err as Error).message || 'Unknown fallback error',
              fallbackErrorStack: (err as Error).stack,
            };
        }
      }
      html ??= '<p>An unexpected error occurred</p>';

      if (routerCtx.location) {
        // Somewhere a `<Redirect>` was rendered
        return {
          renderedHtml: isPage
            ? ({
                componentHtml: '',
                redirectTo:
                  routerCtx.location.pathname + routerCtx.location.search,
              } as unknown as string)
            : '',
        };
      } else {
        return {
          renderedHtml: isPage
            ? ({
                ...headCtx,
                componentHtml: html,
                // NOTE: `react_on_rails` only supports string values on the `renderedHtml` object
                ...(renderError || {}),
              } as unknown as string)
            : html,
        };
      }
    } else {
      const history = getBrowserHistory();

      return function AppRoot() {
        return (
          <StrictMode>
            <BrowserRouter history={history}>
              <AppContainer
                preloadData={preloadData}
                railsCtx={railsCtx}
                isPage={isPage}
                hasError={
                  isPage &&
                  !!document.querySelector('meta[name="react-render-error"]')
                }
              >
                <Comp {...props} />
              </AppContainer>
            </BrowserRouter>
          </StrictMode>
        );
      };
    }
  };

  renderFn.renderFunction = true;

  // make types work with: ReactOnRails.register({ })
  return renderFn as ReactComponentOrRenderFunction;
};
