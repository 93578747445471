import * as _ from 'lodash-es';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { useLocation } from 'components/Link';
import { useIsomorphicLayoutEffect } from 'lib/hooks/useIsomorphicLayoutEffect';
import { IS_DEV } from 'lib/env';

/*
 * Similar functionality as `react-helmet` library i.e.
 * allows setting server-side <title/> and other <head/> elements.
 *
 * Also, <title/> will update `document.title` dynamically if it changes.
 */

export type HeadCtx = {
  headHtml?: string;
  headTitle?: string;
};

type HeadCtxValue = {
  headEls: React.ReactElement[];
  titleEl: React.ReactElement | null;
} | null;

const HeadContext = React.createContext<HeadCtxValue>(null);

// NOTE: cannot use `renderToStaticMarkup` b/c it would use html-escaping e.g. '&' gets converted to '&amp;'
const renderTitle = (titleEl: React.ReactElement): string => {
  try {
    return React.Children.map(titleEl.props.children, (el) => {
      if (el == null || el === false) return '';
      if (typeof el === 'string' || typeof el === 'number') return el;
      if (IS_DEV) console.warn('Encountered non-string element in <title/>');
      return '';
    }).join('');
  } catch {
    return '';
  }
};

export const HeadProvider: React.FC<{
  children: React.ReactNode;
  context?: HeadCtx;
}> = ({ children, context }) => {
  const headCtxValue: HeadCtxValue =
    typeof window === 'undefined'
      ? {
          headEls: [],
          titleEl: null,
        }
      : null;

  const RunAfter =
    typeof window === 'undefined'
      ? () => {
          if (!context || !headCtxValue) return null;

          let k = 0;
          context.headHtml = renderToStaticMarkup(
            <>
              {_.uniqBy(headCtxValue.headEls, (el) =>
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                el.props.key !== undefined ? `${el.props.key}__k` : `${k++}__n`,
              ).map((el, i) => React.cloneElement(el, { key: i }))}
            </>,
          );

          if (headCtxValue.titleEl)
            context.headTitle = renderTitle(headCtxValue.titleEl);

          return null;
        }
      : () => null;

  return (
    <HeadContext.Provider value={headCtxValue}>
      {children}
      <RunAfter />
    </HeadContext.Provider>
  );
};

let clientTitleEl: React.ReactElement | null = null;

export const Head: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const headCtxValue = React.useContext(HeadContext);

  const addHeadEl = (el: React.ReactElement) => {
    if (el.type === 'title') {
      clientTitleEl = el;
      if (headCtxValue) headCtxValue.titleEl = el;
    } else if (typeof window === 'undefined') {
      // only on server
      headCtxValue?.headEls.unshift(el);
    }
  };

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        React.Children.forEach(child.props.children, (fragChild) => {
          if (
            React.isValidElement(fragChild) &&
            typeof fragChild.type === 'string'
          ) {
            addHeadEl(fragChild);
          }
        });
      } else if (typeof child.type === 'string') {
        addHeadEl(child);
      }
    }
  });

  const location = useLocation();
  useIsomorphicLayoutEffect(() => {
    if (clientTitleEl) document.title = renderTitle(clientTitleEl);
  }, [location]);

  return null;
};
