import { useCallback, useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { useToast } from '@chakra-ui/react';

import { RequestError, request, toFormData } from 'lib/request';
import { useRootData } from 'lib/pageData';
import { useEnrollments } from 'lib/userData';
import { addUrlQuery } from 'lib/utils';
import { useCurrentEnrollment } from 'bundles/Classroom/hooks/useCurrentEnrollment';
import {
  ClassroomProps,
  DynamicData,
  DYNAMIC_DATA_MAP,
} from 'bundles/Classroom/types';
import { useRouteParams } from 'lib/routeBuilder';
import { ROUTES } from 'bundles/Classroom/routes';
import { transformLessonDatas } from 'lib/courseDataHelpers';

export const useClassroomConfig = () => useRootData<ClassroomProps>().config;

export { useEnrollments, useCurrentEnrollment };

export const useApiBasePath = <
  OverrideId extends RecordId | null,
  Optional extends boolean,
>(
  overrideId?: OverrideId,
  optional?: Optional,
) => {
  type Return = OverrideId extends RecordId
    ? string
    : Optional extends true
    ? string | null
    : string;

  const currentOrRelevant = useCurrentEnrollment('blank');

  const id = overrideId ?? currentOrRelevant?.id;
  if (id == null) {
    if (optional) return null as Return;
    else throw new Error('Missing current enrollment');
  }

  return `/enrollments/${id}` as Return;
};

/**
 * This hook is just a wrapper around useSWR that does:
 * - disables default revalidations, i.e. the same config as using `useImmutableSWR`
 * - prepends '/enrollments/:current_enrollment_id' to the api route
 */
export const useDynamicData = <Key extends keyof DynamicData>(
  fetchKey: Key,
  {
    enabled = true,
    query,
    overrideEnrollmentId,

    // default `swr` options:
    revalidateOnFocus = false,
    revalidateIfStale = false,
    revalidateOnReconnect = false,
    ...options
  }: SWRConfiguration<DynamicData[Key]> & {
    enabled?: boolean;
    query?: Record<string, any>;
    overrideEnrollmentId?: number;
  } = {},
) => {
  const currentEnrollmentId = useCurrentEnrollment('blank')?.id;
  const enrollmentId = overrideEnrollmentId ?? currentEnrollmentId;

  let cacheKey: string | null = null;
  if (enabled && enrollmentId) {
    cacheKey = DYNAMIC_DATA_MAP[fetchKey]?.(enrollmentId);
    if (!cacheKey) throw new Error(`Invalid dynamic data key: ${fetchKey}`);
  }

  if (cacheKey && query) cacheKey = addUrlQuery(cacheKey, query);

  const res = useSWR<DynamicData[Key], RequestError>(cacheKey, {
    ...options,
    revalidateOnFocus,
    revalidateIfStale,
    revalidateOnReconnect,
  });

  return res;
};

export const useEnrollmentResources = () => useDynamicData('resources');

export const useReferralData = () => {
  const { data: { referral_dashboard_link: referralDashboardLink } = {} } =
    useEnrollmentResources();

  return {
    referralDashboardLink,
  };
};

export const useCareerResources = (revalidateOnFocus = true) => {
  const currentEnrollment = useCurrentEnrollment('blank');

  const {
    data,
    mutate,
    isValidating: isLoading,
    error: requestError,
  } = useDynamicData('career', {
    revalidateOnFocus,
    revalidateIfStale: true,
    enabled:
      !!currentEnrollment?.can_access_career_portal ||
      !!currentEnrollment?.can_access_resume_services,
  });

  // raise to ErrorBoundary only within the career portal
  const raiseOnError = !!useRouteParams(ROUTES.career_portal);

  if (requestError && raiseOnError) throw requestError;

  return {
    isLoaded: !!data,
    isLoading,
    careerTasks: data?.career_tasks,
    careerEnrolled: !!data?.career_enrolled,
    premiumSiteMatchingEnabled: !!data?.premium_site_matching_enabled,
    careerEnrollable: !!data?.career_enrollable,
    careerEligible: !!data?.career_eligible,
    careerExitEligible: !!data?.career_exit_eligible,
    externshipOptedOut: !!data?.externship && !!data?.externship_opted_out,
    externshipEligible: !!data?.externship_eligible,
    jobCoachingOptedOut: !!data?.job_coaching_opted_out,
    jobCoachingEligible: !!data?.job_coaching_eligible,
    enrolledInCareerServicesCohort: !!data?.enrolled_in_career_services_cohort,
    careerPreviouslyOptedIn: !!data?.career_previously_opted_in,
    grade: data?.grade,
    hired: !!data?.hired,
    coach: data?.coach,
    graduated: currentEnrollment?.graduation_status === 'graduated',
    placementCoach: data?.placement_coach,
    jobCoaching: data?.job_coaching,
    expectedCoachingStartDate: data?.expected_coaching_start_date,
    resume: data?.resume,
    screeningReports: data?.screening_reports,
    externship: data?.externship,
    canUploadResume: !!data?.can_upload_resume,
    anyOptedOut: data?.externship_opted_out || data?.job_coaching_opted_out,
    allOptedOut: data?.externship_opted_out && data?.job_coaching_opted_out,
    mutate,
    careerQuestionnairesMap: data?.career_forms || {},
    careerWidgetOptions: data?.career_widget_options,
    likelyToGraduate: data?.likely_to_graduate,
    contactClinicFromNumber: data?.contact_clinic_from_number,
    careerServicesOpeningWeek: data?.career_services_opening_week,
    externshipSignupCountsByMonth: data?.externship_signup_counts_by_month,
    maxSignupCountByMonth: data?.max_signup_count_by_month,
    externshipExperienceType: data?.externship_experience_type,
  };
};

export type CareerResourcesHookReturn = ReturnType<typeof useCareerResources>;

export const useResourcesCourseData = (raiseOnError = true) => {
  const {
    data,
    isValidating: isLoading,
    error: requestError,
  } = useDynamicData('career_resources_course_data', {
    revalidateOnFocus: true,
    revalidateIfStale: true,
  });

  const enr = useCurrentEnrollment('blank');

  if (requestError && raiseOnError) throw requestError;

  return {
    isLoading,
    ...useMemo(() => {
      const courseData = data?.resources_course_data;
      if (!courseData || !enr) return {};

      const transformed = transformLessonDatas(
        courseData.nav_groups,
        courseData.modules,
        enr.cohort.slug,
        enr.cohort.first_monday,
      );

      return {
        allContainers: transformed.allContainers,
      };
    }, [data, enr]),
  };
};

type ActiveStorageFileWithExternalUrl = ActiveStorageFile & {
  external_url: string | null;
};

export type QuestionResult = {
  score: number | null;
  correct_indices: number[] | null;
  choice_indices: number[] | null; // indices of the choices that the user chose
  free_response: string | null;
  video_recording_session_id: string | null;
  feedback: string | null;
  file: ActiveStorageFileWithExternalUrl | undefined;
  updated_at: TimestampString | null;
};

type NhaApplicationCreatePayload = {
  exam_date: TimestampString;
  registration_proof: File;
};

export const useCreateNhaApplication = () => {
  const currentEnrollment = useCurrentEnrollment('blank');
  const toast = useToast();

  const createNhaApplication = useCallback(
    async (body: NhaApplicationCreatePayload) => {
      if (!currentEnrollment) return;

      await request({
        url:
          DYNAMIC_DATA_MAP.career(currentEnrollment.id) + '/nha_applications',
        method: 'POST',
        body: toFormData(body),
      });

      toast({
        title: 'Thanks for letting us know!',
        status: 'success',
      });
    },
    [currentEnrollment, toast],
  );

  return { createNhaApplication };
};

export type CreateNhaApplication = NonNullable<
  ReturnType<typeof useCreateNhaApplication>
>;
