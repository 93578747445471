import * as _ from 'lodash-es';

import {
  differenceInCalendarWeeks,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
} from 'date-fns';

import { ROUTES } from 'bundles/Classroom/routes';
import type { LessonType } from 'lib/const/courseContent';
import type {
  ApiCourseLesson,
  ApiCourseLessonContainer,
  ApiCourseNavGroup,
  AssignmentUserProgress,
  CourseLesson,
  CourseLessonContainer,
  CourseModule,
  CourseNavGroup,
  LessonStatus,
  ProgressStats,
  UpcomingSchedule,
} from 'lib/courseData';
import { addIsoDays } from 'lib/utils/time';
import { parameterize } from 'lib/utils/string';

const justISODate = 'yyyy-MM-dd';
export function dateToLessonKey(date: Date): DateString {
  return format(date, justISODate);
}

export function lessonKeyToDate(dayString: DateString): Date {
  return parseISO(dayString);
}

export function getDateOnly(dateTime: DateWithoutTimezoneString): Date {
  return parseISO(dateTime.slice(0, justISODate.length));
}

export const getWeekIndex = (
  cohortFirstMonday: DateString,
  date: DateString | Date,
) => {
  const firstMonday = parseISO(cohortFirstMonday + 'T12:00:00-05:00');
  const day = date instanceof Date ? date : parseISO(date + 'T12:00:00-05:00');
  return differenceInCalendarWeeks(day, firstMonday, { weekStartsOn: 1 });
};

export function findSchedule({
  upcomingSchedules,
  contentLessonId,
}: {
  upcomingSchedules?: UpcomingSchedule[] | null;
  contentLessonId: RecordId;
}) {
  return upcomingSchedules?.find(
    (schedule) => schedule.content_lesson_id === contentLessonId,
  );
}

export const INTERESTING_LESSON_TYPES = [
  'lecture',
  'group_project',
  'group_session',
  'exam',
] satisfies LessonType[];

/** Get's next unfinished lesson, or first lesson if all are finished */
export function getNextUnfinishedLesson(
  lessons: CourseLesson[],
): CourseLesson | undefined {
  if (lessons.length === 0) return undefined;

  return lessons.find((les) => les.status !== 'completed') || lessons[0];
}

/**
 * Used to get the next actionable child-lesson is either 'in_progress', 'not_started', or 'complete'
 */
export const getNextActionableLesson = (
  lessons?: CourseLesson[],
): CourseLesson | null => {
  if (!lessons?.length) return null;

  return (
    // get first 'in_progress' lesson
    lessons.find((l) => l.status === 'in_progress') ||
    // get first 'not_started' lesson
    lessons.find((l) => l.status === 'not_started') ||
    // get first lesson if all are 'complete'
    lessons[0]
  );
};

function createEmptyDay(deliverDate: DateString) {
  return {
    deliverDate,
    lessons: [],
  };
}

/**
 * Adds empty days to week to make sure each day Mon to Fri is there
 */
export function fillOutWeek<T>(
  weekStartMonday: DateString,
  lessonDays: { deliverDate: DateString; lessons: T[] }[],
  fillDaysSinceMonday = 5, // fill out 5 days since Mon (inclusive) e.g. 5 = Mon to Fri
): { deliverDate: DateString; lessons: T[] }[] {
  return _.times(7, (dayOffset) => {
    const deliverDate = addIsoDays(weekStartMonday, dayOffset);
    const lessonDay = lessonDays.find((d) => d.deliverDate === deliverDate);
    if (lessonDay) return lessonDay;
    if (dayOffset >= fillDaysSinceMonday) {
      return null; // do not fill in weekends
    }
    return createEmptyDay(deliverDate);
  }).filter(<E>(d: E): d is NonNullable<E> => d != null);
}

/**
 * If everything but empty days are completed add weekFinished flag for visualization
 */
export function addWeekFinishedIfNeeded<
  D extends { lessons: { progressValue: number }[] },
>(days: D[]) {
  if (days.length === 0) return days;

  const isEmpty = !days.some(({ lessons }) => lessons.length > 0);
  const completedWeek = days.every(({ lessons }) =>
    lessons.every(({ progressValue }) => progressValue >= 100),
  );

  if (isEmpty || !completedWeek) return days;

  return days.map((day) => {
    return {
      ...day,
      weekFinished: true,
    };
  });
}

export function ensureUniqueTitles<T extends { title: string }>(
  cards: T[],
): T[] {
  const titleList: Record<string, number> = {};
  return cards.map((card) => {
    if (titleList[card.title]) {
      titleList[card.title] += 1;
      return {
        ...card,
        title: `${card.title} - ${titleList[card.title]}`,
      };
    }
    titleList[card.title] = 1;
    return card;
  });
}

export type LessonCardType =
  | 'lecture'
  | 'group_project'
  | 'group_session'
  | 'community_participation'
  | 'exam'
  | 'async';

export type SortableCards = {
  lesson_type: LessonCardType;
};

export function orderLessonCards<T extends SortableCards>(cards: T[]): T[] {
  return _.sortBy(cards, (item) => {
    const ordering = (INTERESTING_LESSON_TYPES as string[]).indexOf(
      item.lesson_type as string,
    );
    if (ordering === -1) return INTERESTING_LESSON_TYPES.length;
    return ordering;
  });
}

export function computeLocked({
  todayDate,
  deliver_at,
  locked_until,
  lockAfterToday,
}: {
  todayDate: Date;
  deliver_at: DateWithoutTimezoneString;
  locked_until?: TimestampString | null;
  lockAfterToday: boolean;
}) {
  todayDate = startOfDay(todayDate);
  const startDate = startOfDay(getDateOnly(deliver_at));

  let locked = false;
  if (locked_until != null) {
    const lockedDate = startOfDay(getDateOnly(locked_until));
    locked = isBefore(todayDate, lockedDate);
  }

  if (lockAfterToday && isAfter(startDate, todayDate)) {
    locked = true;
  }
  return locked;
}

// this doesn't account for category weights
export function computeGrade({
  all_grades,
  contentLessonIds,
}: {
  all_grades: ProgressStats['all_grades'];
  contentLessonIds: RecordId[];
}) {
  if (!contentLessonIds?.length) return undefined;

  const myGrades = all_grades.filter((grade) =>
    contentLessonIds.includes(grade.content_lesson_id),
  );

  if (myGrades.length === 0) return undefined;

  return {
    score: myGrades.some((grade) => grade.score != null)
      ? _.meanBy(myGrades, (g) => g.score ?? 0)
      : null,
    completed_or_missing: myGrades.some((g) => g.completed_or_missing),
  };
}

export function computeStreakDays({
  todayDate,
  lessonsPerDay = {},
}: {
  todayDate: Date;
  lessonsPerDay?: {
    [deliverDate in DateString]?: Pick<CourseLesson, 'status'>[];
  };
}) {
  let isCompletedPerDay = _.map(lessonsPerDay, (dayLessons, lessonKey) => {
    const completed = dayLessons!.every((les) => {
      return les.status === 'completed';
    });
    return { lessonKey, completed };
  });
  isCompletedPerDay = _.sortBy(isCompletedPerDay, (d) => d.lessonKey);

  const latest_index = _.findLastIndex(
    isCompletedPerDay,
    ({ lessonKey }) => !isAfter(lessonKeyToDate(lessonKey), todayDate),
  );

  let streak_days = 0;

  for (let i = latest_index; i >= 0; i--) {
    if (isCompletedPerDay[i].completed) {
      streak_days += 1;
    } else {
      break; // stop counting
    }
  }

  if (streak_days > 0) {
    // count into the future
    for (let i = latest_index + 1; i < isCompletedPerDay.length; i++) {
      if (isCompletedPerDay[i].completed) {
        streak_days += 1;
      } else {
        break; // stop counting
      }
    }
  }

  return streak_days;
}

export const getLessonName = (
  lessonOrContainer: CourseLessonContainer | CourseLesson,
) => {
  const container =
    'container' in lessonOrContainer
      ? lessonOrContainer.container
      : lessonOrContainer;

  return [
    container.unit_name,
    lessonOrContainer.name || container.lessons[0].name,
  ]
    .filter(Boolean)
    .join(' ');
};

export const getLessonProgressRatio = (
  lessons: Pick<CourseLesson, 'status' | 'optional'>[],
  countOptional = false,
) => {
  let total = 0;
  let completed = 0.0;

  for (const lit of lessons) {
    if (lit.optional && !countOptional) continue;

    total++;
    if (lit.status === 'completed') completed += 1.0;
    else if (lit.status === 'in_progress') completed += 0.5;
  }

  return total > 0 ? _.round(completed / total, 2) : 0;
};

// this function is duplicated on the backend as `label_for_url`
const lessonLabelForUrl = (
  container: CourseLessonContainer | ApiCourseLessonContainer,
  lesson: CourseLesson | ApiCourseLesson,
) => {
  const inWords = parameterize(
    [container.name, lesson.name].filter(Boolean).join('-'),
  ).split('-');

  const outWords = [];
  let length = 0;
  for (let i = 0; i < inWords.length; i++) {
    const w = inWords[i];
    if (i > 0 && length + w.length >= 64) break;
    length += w.length + 1;
    outWords.push(w);
  }

  return outWords.join('-');
};

/**
 * Returns the lesson-content (`activeLesson`) for the currently active page.
 * This is determined from the page url i.e. the route `params`.
 */
export const getActivePageData = (
  navItems: CourseNavGroup[],
  schLessonId: RecordId,
): {
  activeLesson: CourseLesson;
  prevLesson?: CourseLesson;
  nextLesson?: CourseLesson;
} | null => {
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < navItems.length; i++) {
    const navGroup = navItems[i];

    for (let j = 0; j < navGroup.lesson_containers.length; j++) {
      const lessonContainer = navGroup.lesson_containers[j];

      for (let k = 0; k < lessonContainer.lessons.length; k++) {
        const lesson = lessonContainer.lessons[k];

        if (lesson.scheduled_lesson_id === schLessonId) {
          // NOTE: this gets the next/previous lesson only within the same navGroup
          const prevLesson =
            lessonContainer.lessons[k - 1] ||
            _.last(navGroup.lesson_containers[j - 1]?.lessons);

          const nextLesson =
            lessonContainer.lessons[k + 1] ||
            navGroup.lesson_containers[j + 1]?.lessons[0];

          return {
            // activeNavGroup: navGroup,
            // activeModule: lessonContainer.module,
            // activeContainer: lessonContainer,
            activeLesson: lesson,
            prevLesson,
            nextLesson,
          };
        }
      }
    }
  }

  return null;
};

export const transformLessonDatas = (
  apiNavGroups: ApiCourseNavGroup[],
  allModules: CourseModule[],
  course_slug: string,
  cohortFirstMonday: DateString,
  progressStats?: Pick<ProgressStats, 'all_grades' | 'content_lesson_statuses'>,
) => {
  const statusMap = progressStats?.content_lesson_statuses || {};

  const assignmentMap = new Map<RecordId, AssignmentUserProgress>();
  for (const g of progressStats?.all_grades || []) {
    assignmentMap.set(g.content_lesson_id, g);
  }

  const modulesMap = _.keyBy(allModules, (m) => m.id);

  const navItems = apiNavGroups.map((apiNavGroup) => {
    const containers = apiNavGroup.lesson_containers.map((apiContainer) => {
      const lessons = apiContainer.lessons.map((apiLesson) => {
        const status = statusMap[apiLesson.content_lesson_id] || 'not_started';

        const deadline_at =
          // get the deadline override
          assignmentMap.get(apiLesson.content_lesson_id)
            ?.extended_deadline_at || apiLesson.deadline_at;

        // TODO: pass `deliver_date` directly from the backend
        const deliverDate = apiLesson.deliver_at.slice(0, 10);

        const deliverWeekIndex = getWeekIndex(cohortFirstMonday, deliverDate);

        const lesson: CourseLesson = {
          ...apiLesson,
          deadline_at,
          deadlineAt: parseISO(deadline_at).getTime(),
          deliverDate,
          deliverWeekIndex,
          status,
          progressRatio: getLessonProgressRatio(
            [{ ...apiLesson, status }],
            true,
          ),
          path: ROUTES.lesson.path({
            course_slug,
            scheduled_lesson_id: apiLesson.scheduled_lesson_id,
            label_for_url: lessonLabelForUrl(apiContainer, apiLesson),
          }),
          container: undefined as unknown as CourseLessonContainer,
        };

        return lesson;
      });

      let parentStatus: LessonStatus;
      if (lessons.every((l) => l.status === 'completed')) {
        parentStatus = 'completed';
      } else if (lessons.every((l) => l.status === 'not_started')) {
        parentStatus = 'not_started';
      } else {
        parentStatus = 'in_progress';
      }

      // these should be the same for all lessons in a container
      const deliverDate = lessons[0].deliverDate;
      const deliverWeekIndex = lessons[0].deliverWeekIndex;

      const progressRatio = getLessonProgressRatio(lessons, true);

      const mod = modulesMap[apiContainer.module_id];
      if (!mod) throw new Error(`Missing module ${apiContainer.module_id}`);

      const container: CourseLessonContainer = {
        ...apiContainer,
        module: mod,
        navGroup: undefined as unknown as CourseNavGroup,
        status: parentStatus,
        progressRatio,
        lessons,

        deliverDate,
        deliverWeekIndex,
      };

      for (const lesson of lessons) {
        lesson.container = container;
      }

      return container;
    });

    const navGroup: CourseNavGroup = {
      ...apiNavGroup,
      lesson_containers: containers,
    };

    for (const container of containers) {
      container.navGroup = navGroup;
    }

    return navGroup;
  });

  const allContainers = navItems.flatMap((nit) => nit.lesson_containers);
  const allLessons = allContainers.flatMap((c) => c.lessons);

  return {
    allContainers,
    allLessons,
    navItems,
  };
};

export const getLessonsPerDay = (allLessons: CourseLesson[]) => {
  return _.groupBy(allLessons, (lwp) => lwp.deliverDate) as {
    // each value should be typed as optional i.e. `?:`
    [deliverDate in DateString]?: CourseLesson[];
  };
};

export const getLessonsPerWeek = (
  allLessons: CourseLesson[],
  cohortFirstMonday: DateString,
  cohortEndDate: DateString,
) => {
  const perWeek = _.groupBy(
    allLessons.filter((les) => les.container.navGroup.namespace === 'learning'),
    (lwp) => lwp.deliverWeekIndex,
  );
  const weekIndexes = Object.keys(perWeek)
    .map((d) => Number(d))
    .sort((a, b) => a - b);

  const firstWeekIndex = weekIndexes[0]; // or Math.min(weekIndexes[0], 0) ?
  const lastEnabledWeekIndex = weekIndexes[weekIndexes.length - 1];
  const lastWeekIndex = Math.max(
    lastEnabledWeekIndex,
    getWeekIndex(cohortFirstMonday, cohortEndDate),
  );

  // fill in empty weeks e.g. [-1,0,3,4,6] -> [-1,0,1,2,3,4,5,6]
  const fullWeekIndexes: number[] = [];
  for (let wi = firstWeekIndex; wi <= lastWeekIndex; wi++)
    fullWeekIndexes.push(wi);

  return fullWeekIndexes.map((weekIndex) => {
    const lessons = perWeek[weekIndex] || [];

    const weekLabel =
      lessons[0]?.container.navGroup.name || `Week ${weekIndex + 1}`;

    const weekStartDate = addIsoDays(cohortFirstMonday, weekIndex * 7);

    const perDay = _.sortBy(
      Object.values(_.groupBy(lessons, (l) => l.deliverDate)).map((inDay) => {
        return {
          deliverDate: inDay[0].deliverDate,
          lessons: inDay,
        };
      }),
      (d) => d.deliverDate,
    );

    const enabled = weekIndex <= lastEnabledWeekIndex;

    return {
      weekStartDate,
      weekIndex,
      weekLabel,
      lessons,
      perDay,
      disabled: !enabled,
    };
  });
};
