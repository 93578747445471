import { routePrefixFactory } from 'lib/routeBuilder';

const opsRoute = routePrefixFactory('/operations');
const instructorRoute = routePrefixFactory('/instructor');

export const ROUTES = {
  // resume dashboard:
  resumes: opsRoute('/resumes'),

  // externship dashboard:
  externship: opsRoute('/externship'),
  externship_home: opsRoute('/externship/home'),

  // career coaching:
  coaching: opsRoute('/coaching'),
  coaching_student: opsRoute('/coaching/students/:enrollment_id'),

  // content editor:
  content_editor_dashboard: opsRoute('/course_contents'),
  content_editor_show: opsRoute([
    '/course_contents/:content_proxy_id',
    '/course_contents/:content_proxy_id/:show_page',
  ] as const),

  // learning dashboard:
  show_student: instructorRoute('/:group_id/students/:enrollment_id'),
  students: instructorRoute('/:group_id/students'),
  curriculum: instructorRoute('/:group_id/curriculum'),
  show_group_project: instructorRoute(
    '/:group_id/group_projects/:assignment_id',
  ),
  group_projects: instructorRoute('/:group_id/group_projects'),
  schedule: instructorRoute('/schedule'),
  home: instructorRoute(''),
  outreach: instructorRoute('/outreach'),
  show_manual_grade: instructorRoute(
    '/:group_id/manual_grading/:assignment_id',
  ),
  manual_grading: instructorRoute('/:group_id/manual_grading'),
};
