import { createContext, useContext, useEffect, useState } from 'react';
import type { RailsContext } from 'react-on-rails/node_package/lib/types';

type CustomRailsCtx = {
  timezone: string;
  viewport: 'mobile' | 'desktop';
  impersonating?: boolean;
};
export type RailsCtx = RailsContext & CustomRailsCtx;

const SessionContext = createContext<RailsCtx | null>(null);

export const SessionProvider: React.FC<{
  value: RailsCtx;
  children: React.ReactNode;
}> = ({ value, children }) => {
  const [timezone, setTimeZone] = useState<string>(value.timezone);

  useEffect(() => {
    try {
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch {}
  }, []);

  return (
    <SessionContext.Provider value={{ ...value, timezone }}>
      {children}
    </SessionContext.Provider>
  );
};

/**
 * During first render (SSR), this is the server-provided value, `User#timezone`.
 * After that, this is the browser's timezone.
 */
export const useTimezone = () => {
  return useContext(SessionContext)?.timezone || null;
};

export const useSessionContext = () => {
  return useContext(SessionContext);
};
