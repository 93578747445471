import { buildRoute } from 'lib/routeBuilder';

export const ROUTES = {
  // auth routes:
  sign_in: buildRoute('/users/sign_in'),
  new_password: buildRoute('/users/password/new'),
  edit_password: buildRoute('/users/password/edit'),

  // signup flow routes:
  signup_flow: buildRoute([
    '/curricula/:slug/signup/secure-your-spot',
    '/curricula/:slug/signup/secure-your-spot/:action_code',
  ] as const),

  payment_successful: buildRoute('/curricula/:slug/signup/payment-successful'),

  bundle: buildRoute('/curricula/:slug/signup/bundle/:bundle_slug'),
  bundle_success: buildRoute(
    '/curricula/:slug/signup/bundle/:bundle_slug/success',
  ),
};
