import * as _ from 'lodash-es';
import React, { Component, useEffect, useRef } from 'react';

import { useLocation } from 'components/Link';
import { trackError } from 'lib/errorTracking';

const ErrorReset: React.FC<{ onReset: () => void }> = ({ onReset }) => {
  const location = useLocation();

  const firstLocation = useRef(location);
  useEffect(() => {
    if (firstLocation.current === location) return;

    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};

export type ErrorFallbackProps = {
  error?: Error | null;
  resetErrorBoundary?: () => void;
};

export class ErrorBoundary extends Component<
  {
    children?: React.ReactNode;
    fallback?: React.ReactNode | React.ComponentType<ErrorFallbackProps>;
    hasError?: boolean;
  },
  { error: Error | null; hasError: boolean }
> {
  state = { error: null, hasError: this.props.hasError ?? false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    void trackError(error, errorInfo);
  }

  resetError = () => {
    this.setState({ error: null, hasError: false });
  };

  render() {
    const { error, hasError } = this.state;
    const { fallback: Fallback, children } = this.props;

    return (
      <>
        {hasError ? (
          _.isFunction(Fallback) ? (
            <Fallback error={error} resetErrorBoundary={this.resetError} />
          ) : (
            Fallback
          )
        ) : (
          children
        )}
        {/* Reset ErrorBoundary when react-router changes location */}
        <ErrorReset onReset={this.resetError} />
      </>
    );
  }
}

export const withErrorBoundary = <C extends React.ComponentType<any>>(
  Comp: C,
  fallback?: React.ReactNode | React.ComponentType,
) =>
  ((props: any) => (
    <ErrorBoundary fallback={fallback}>
      <Comp {...props} />
    </ErrorBoundary>
  )) as C;
